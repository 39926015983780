import React from "react";
import LibroBanner from "../asset/LibroBannerSito.png";
import "../Style/pageSecondary.css";
import { FaArrowDown } from "react-icons/fa";

const DownloadPDFButton = () => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = "/e-book-InvestireNelMattone.pdf"; // Percorso al file PDF nella cartella public
    link.download = "e-book-InvestireNelMattone.pdf"; // Nome del file che verrà scaricato
    link.click();
  };

  return (
    <div className="text-center d-flex flex-column align-items-center justify-content-center width-70-LG">
      <img src={LibroBanner} alt="" className="w-100 mb-3 " />
      <span className="mb-4">
        <FaArrowDown className="fs-3" />
      </span>

      <button onClick={handleDownload} className="btn btn-primary ">
        Scarica il nostro eBook in PDF
      </button>
    </div>
  );
};

export default DownloadPDFButton;
